import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { ApiRoute } from 'environments/apiroute.prod';
import { Endpoint } from 'environments/endpoint.prod';
import { LookupModuleModel } from '../admin-management/model/lookup-module.model';
import { LookupModel } from '../admin-management/model/lookup.model';
import { ModuleModel } from '../admin-management/model/module.model';
import { PermissionModel } from '../admin-management/model/permission.model';
import { RoleModel } from '../admin-management/model/role.model';
import { SaveRoleModel } from '../admin-management/model/save-role-request.model';
import { lookup } from '../modals/lookup';

@Injectable()
export class AdminConfigService {

  lookups = new Map()

  /**
   * Constructor
   *
   * @param {HttpClient} _httpClient
   */
  constructor(private _httpClient: HttpClient) {
  }


  
  /**
   * Get row 
   */
  async getTemplate(id: any): Promise<any> {
    return this._httpClient.get(`${Endpoint.ADMIN_CONFIG_MICROSERVICE + ApiRoute.GET_TEMPLATE_BY_MODULE_NAME + id}`).toPromise();
  }

  async filterDocTemplates(data) {
    return this._httpClient.post(`${Endpoint.ADMIN_CONFIG_MICROSERVICE + ApiRoute.FILTER_DOC_TEMPLATES}`, data).toPromise();
  }

  async getLookups(lookupName: string): Promise<any> {
    if(!this.lookups.size){
    let resp = await this.getAlllookupsData()
    let modules = resp['module'].forEach(ele => {
        this.lookups.set(ele.name ,resp['lookups'].filter(ele1 =>  ele1.name == ele.name))
    })
  }  
    return  this.lookups.get(lookupName)

  }

  async getAlllookupsData (){
    return this._httpClient.get<lookup[]>(`${Endpoint.ADMIN_CONFIG_MICROSERVICE + ApiRoute.GET_LOOKUPS_BY_MODULE_NAME}`).toPromise();
  }

  async getLookupsForPublic(lookupName: string): Promise<any> {
    return this._httpClient.get<lookup[]>(`${Endpoint.ADMIN_CONFIG_MICROSERVICE + ApiRoute.GET_LOOKUPS_PUBLIC_BY_MODULE_NAME + lookupName}`).toPromise();
  }

  getRoles(): Promise<RoleModel[]> {
    return this._httpClient.get<RoleModel[]>(Endpoint.ADMIN_CONFIG_MICROSERVICE + ApiRoute.GET_ROLES).toPromise();
  }

  getPermissions(): Promise<PermissionModel[]> {
    return this._httpClient.get<PermissionModel[]>(Endpoint.ADMIN_CONFIG_MICROSERVICE + ApiRoute.GET_PERMISSIONS).toPromise();
  }

  getPermissionByRole(id: string): Promise<PermissionModel[]> {
    return this._httpClient.get<PermissionModel[]>(`${Endpoint.ADMIN_CONFIG_MICROSERVICE + ApiRoute.GET_ROLES}/${id}/permissions`).toPromise();
  }

  createRole(request: SaveRoleModel): Promise<RoleModel> {
    return this._httpClient.post<RoleModel>(Endpoint.ADMIN_CONFIG_MICROSERVICE + ApiRoute.GET_ROLES, request).toPromise();
  }
  
  updateRole(id: string, request: SaveRoleModel) : Promise<RoleModel> {
    return this._httpClient.put<RoleModel>(`${Endpoint.ADMIN_CONFIG_MICROSERVICE + ApiRoute.GET_ROLES}/${id}`, request).toPromise();
  }

  getModules(): Promise<any> {
    return this._httpClient.get<ModuleModel[]>(Endpoint.ADMIN_CONFIG_MICROSERVICE + ApiRoute.GET_ALL_MODULES).toPromise();
  }

  updateModule(id: string, request) : Promise<any> {
    return this._httpClient.put<ModuleModel>(`${Endpoint.ADMIN_CONFIG_MICROSERVICE + ApiRoute.UPDATE_MODULE}/${id}`, request).toPromise();
  }

  createModule(request: any){
    return this._httpClient.post<ModuleModel>(Endpoint.ADMIN_CONFIG_MICROSERVICE + ApiRoute.CREATE_MODULE, request).toPromise();
  }

  getAllLookups(): Promise<any> {
    return this._httpClient.get<LookupModel[]>(Endpoint.ADMIN_CONFIG_MICROSERVICE + ApiRoute.GET_ALL_LOOKUPS).toPromise();
  }

  getAllMasterLookups(): Promise<any> {
    return this._httpClient.get<LookupModel[]>(Endpoint.ADMIN_CONFIG_MICROSERVICE + ApiRoute.GET_ALL_MASTER_LOOKUPS).toPromise();
  }

  getActiveModules(): Promise<any> {
    return this._httpClient.get<ModuleModel[]>(Endpoint.ADMIN_CONFIG_MICROSERVICE + ApiRoute.GET_ACTIVE_MODULES).toPromise();
  }

  getLookupById(id: string) : Promise<any> {
    return this._httpClient.get<LookupModel>(`${Endpoint.ADMIN_CONFIG_MICROSERVICE + ApiRoute.GET_LOOKUPBY_ID}/${id}`).toPromise();
  }

  getMasterLookupById(id: string) : Promise<any> {
    return this._httpClient.get<LookupModel>(`${Endpoint.ADMIN_CONFIG_MICROSERVICE + ApiRoute.GET_MASTER_LOOKUPBY_ID}/${id}`).toPromise();
  }

  updateLookup(id: string, request) : Promise<any> {
    return this._httpClient.put<LookupModel>(`${Endpoint.ADMIN_CONFIG_MICROSERVICE + ApiRoute.UPDATE_LOOKUP}/${id}`, request).toPromise();
  }
  
  createLookup(request: any){
    return this._httpClient.post<any>(Endpoint.ADMIN_CONFIG_MICROSERVICE + ApiRoute.CREATE_LOOKUP, request).toPromise();
  }

  createMasterLookup(request: any){
    return this._httpClient.post<any>(Endpoint.ADMIN_CONFIG_MICROSERVICE + ApiRoute.CREATE_MASTER_LOOKUP, request).toPromise();
  }
  updatemasterLookup(id: string, request) : Promise<any> {
    return this._httpClient.put<LookupModel>(`${Endpoint.ADMIN_CONFIG_MICROSERVICE + ApiRoute.UPDATE_MASTER_LOOKUP}/${id}`, request).toPromise();
  }

  getModulesByLookupId(id:string){
    return this._httpClient.get<LookupModuleModel[]>(`${Endpoint.ADMIN_CONFIG_MICROSERVICE + ApiRoute.GET_MODULE_BY_LOOKUP}/${id}`).toPromise();
  }

  getLawfirm() {
    return this._httpClient.get<any>(Endpoint.CASE_MICROSERVICE+ApiRoute.GET_LAWFIRM).toPromise();
  }

  createLawfirm(request: any){
    return this._httpClient.post<any>(Endpoint.CASE_MICROSERVICE + ApiRoute.CREATE_LAWFIRM, request).toPromise();
  }
  
  deleteteRole(id) : Promise<any> {
    return this._httpClient.delete(`${Endpoint.ADMIN_CONFIG_MICROSERVICE + ApiRoute.GET_ROLES}/${id}`).toPromise();
  }

  createConfig(request: any){
    return this._httpClient.post<ModuleModel>(Endpoint.ADMIN_CONFIG_MICROSERVICE + ApiRoute.CREATE_CONFIG, request).toPromise();
  }

  getfedexConfigByName() {
    return this._httpClient.get<any>(Endpoint.ADMIN_CONFIG_MICROSERVICE+ApiRoute.GET_CONFIG_BYNAME).toPromise();
  }

  getAuthToken(request: any){
    return this._httpClient.post<any>(Endpoint.ADMIN_CONFIG_MICROSERVICE + ApiRoute.GET_AUTH_TOKEN, request).toPromise();
  }

  async getAuthUrl(): Promise<any> {
    return this._httpClient.get(`${Endpoint.ADMIN_CONFIG_MICROSERVICE + '/lookup/getQuickbookAuthUri'}`).toPromise();
  }

  getQuickBooksConfigByName() {
    return this._httpClient.get<any>(Endpoint.ADMIN_CONFIG_MICROSERVICE+ApiRoute.GET_CONFIG_BYNAME_QUICKBOOKS).toPromise();
  }

  createInvoiceConfig(request: any){
    return this._httpClient.post<any>(Endpoint.ADMIN_CONFIG_MICROSERVICE + ApiRoute.CREATE_INVOICE_CONFIG, request).toPromise();
  }


  async getInvoiceConfigs (){
    return this._httpClient.get<lookup[]>(`${Endpoint.ADMIN_CONFIG_MICROSERVICE + ApiRoute.GET_INVOICE_CONFIG}`).toPromise();
  }
  async getAllInvoiceConfigs (){
    return this._httpClient.get<lookup[]>(`${Endpoint.ADMIN_CONFIG_MICROSERVICE + ApiRoute.GET_ALL_INVOICE_CONFIG}`).toPromise();
  }

  getInvoiceConfigById(id: string) : Promise<any> {
    return this._httpClient.get<any>(`${Endpoint.ADMIN_CONFIG_MICROSERVICE + ApiRoute.GET_INVOICE_CONFIG_BY_ID}/${id}`).toPromise();
  }

  deleteInvoiceConfig(id){
    return this._httpClient.delete(Endpoint.ADMIN_CONFIG_MICROSERVICE +ApiRoute.DELETE_INVOICE_CONFIG+ '/'+id).toPromise();
   }

   getConfigByName(name) {
    return this._httpClient.get<any>(Endpoint.ADMIN_CONFIG_MICROSERVICE+ApiRoute.GET_QBCONFIG_BYNAME  + '/' + name ).toPromise();
  }

  createCaseConfig(request: any){
    return this._httpClient.post<any>(Endpoint.ADMIN_CONFIG_MICROSERVICE + ApiRoute.CREATE_CASE_CONFIG, request).toPromise();
  }

  async getCaseConfigs (obj){
    return this._httpClient.post(Endpoint.ADMIN_CONFIG_MICROSERVICE + ApiRoute.GET_ALL_CASE_CONFIG, obj).toPromise();
  }

  async getCaseConfigsByType(type): Promise<any> {
    const caseConfig = JSON.parse(localStorage.getItem('caseConfig'));
    return caseConfig[type]
  }

  async getCaseConfigLookups(obj): Promise<any> {
    return this._httpClient.post<any>(Endpoint.CLOUD_FUNCTIONS + ApiRoute.GET_CASE_CONFIG_LOOKUPS, obj).toPromise();
  }

  async getPdfTemplate(id: string) : Promise<any> {
    return this._httpClient.get<any>(`${Endpoint.ADMIN_CONFIG_MICROSERVICE + ApiRoute.GET_PDF_TEMPLATE_BY_TYPE}/${id}`).toPromise();
  }

  async getCaseToTest(id: string) : Promise<any> {
    return this._httpClient.get<any>(`${Endpoint.ADMIN_CONFIG_MICROSERVICE + ApiRoute.GET_CASE_TO_TEST}/${id}`).toPromise();
  }

  async updateQuickBookConfig(onlyToken) {
    let quickBookConfig = await this._httpClient.get<any>(Endpoint.ADMIN_CONFIG_MICROSERVICE+ApiRoute.REFRESH_QB_ACCESS_TOKEN).toPromise();
    if (quickBookConfig && JSON.stringify(quickBookConfig)) {
      if (onlyToken) {
        var qbConfig = JSON.parse(JSON.stringify(quickBookConfig));
        return qbConfig?.configData?.apiKey.access_token+'#'+qbConfig?.userId;
      } else {
        return quickBookConfig;
      }
    }
  }

  async saveQuickBookDataItems(req) {
    return this._httpClient.post<any>(Endpoint.ADMIN_CONFIG_MICROSERVICE+ApiRoute.SAVE_QUICKBOOKDATA_ITEMS, req).toPromise();
  }


}
